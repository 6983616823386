<template>
  <div id="payment">
    <v-flex xs12>
      <v-layout wrap class="payment">
        <h1 v-if="!isMobile">{{ $t('payment-confirm.title2')}}</h1>
        <p v-if="!isMobile" class="text">
          {{ $t('payment-confirm.subtitle')}}
        </p>
        <p v-if="!isMobile" class="text">
          {{ $t('payment-confirm.subtitle2')}}
        </p>

        <v-flex xs12 sm12 md12>
          <v-form
            ref="form"
            class="mt-9"
            v-model="valid"
            lazy-validation
            @submit="submit()"
          >
            <v-layout wrap>
              <v-flex xs12 sm12 md5>
                <v-text-field
                  v-model="order_no"
                  ref="order_no"
                  :label="$t('payment-confirm.order-number')"
                  :rules="orderNoRules"
                  :outlined="isMobile"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md5 offset-md1>
                <v-text-field
                  v-model="name"
                  ref="name"
                  :label="$t('payment-confirm.account-name')"
                  :rules="nameRules"
                  :outlined="isMobile"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md5>
                <v-text-field
                  v-model="account_no"
                  ref="account_no"
                  :label="$t('payment-confirm.account-number')"
                  :outlined="isMobile"
                  :rules="accountRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md5 offset-md1>
                <v-text-field
                  v-model="amount"
                  ref="amount"
                  :label="$t('payment-confirm.amount')"
                  :outlined="isMobile"
                  :rules="amountRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md5>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      :outlined="isMobile"
                      :label="$t('payment-confirm.payment-date')"
                      append-icon="mdi-calendar"
                      :rules="dateRules"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    scrollable
                    @input="$refs.menu.save(date)"
                  >
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm12 md5 offset-md1>
                <v-combobox
                  v-model="transfer_type"
                  :items="typeList"
                  :label="$t('payment-confirm.transfer-type')"
                  :outlined="isMobile"
                  :rules="typeRules"
                ></v-combobox>
              </v-flex>
            </v-layout>
            <v-flex xs12 sm12 md5 class="mb-9">
              <v-btn class="mt-5" tile color="black" dark width="100%" height="44px" @click="submit()">
                <span class="btn-text"> {{ $t('payment-confirm.confirmation')}} </span>
              </v-btn>
            </v-flex>
          </v-form>
         </v-flex>
      </v-layout>
    </v-flex>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'

  export default {
    name: 'ConfirmPayment',
     computed: {
      ...mapState([
        'isMobile',
        'token'
      ])
    },
    data () {
      return {
        menu: false,
        order_no:'',
        name: '',
        account_no: '',
        amount: '',
        date: '',
        transfer_type: '',
        valid: true,
        orderNoRules: [
          v => !!v || this.$t('payment-confirm.form.error-order-number'),
          v => (v && v.length >= 10) || this.$t('payment-confirm.form.error-order-min-length'),
        ],
        nameRules: [
          v => !!v || this.$t('payment-confirm.form.error-account-name'),
          v => (v && v.length >= 3) || this.$t('payment-confirm.form.error-account-min-length')
        ],
        accountRules: [
          v => !!v || this.$t('payment-confirm.form.error-account-number'),
          v => /^\d+$/.test(v) || this.$t('payment-confirm.form.error-account-format'),
          v => (v && v.length >= 5) || this.$t('payment-confirm.form.error-account-number-min-length'),
        ],
        amountRules: [
          v => !!v || this.$t('payment-confirm.form.error-amount'),
          v => /^\d+$/.test(v) || this.$t('payment-confirm.form.error-amount-format'),
          v => (v && v.length >= 5) || this.$t('payment-confirm.form.error-amount-min-length'),
        ],
        dateRules: [
          v => !!v || this.$t('payment-confirm.form.error-payment-date'),
        ],
        typeRules: [
          v => !!v || this.$t('payment-confirm.form.error-transfer-type'),
        ],
        typeList: [
          'E-Wallet', 'Mobile Banking', 'Internet Banking', 'Transfer ATM', 'Teller'
        ]
      }
    },
    methods: {
      initForm () {
        this.$store.commit('setIsLoading', true)

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token,
          is_offline: true
        }

        axios.get('/api/v1/member/order_history/'+ this.$route.params.id)
          .then(response => {
            this.order_no = response.data.data.sales_header.sales_order_number
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      },
      submit () {
        if (this.$refs.form.validate()) {
          this.$store.commit('setIsLoading', true)

          axios.defaults.headers = {
            id_brand: process.env.VUE_APP_BRAND,
            token: this.token,
            is_offline: true
          }

          axios
            .post('/api/v1/orders/confirm_payment', {
              sales_order_number: this.order_no,
              nama_pemilik_rek: this.name,
              no_rek: this.account_no,
              jumlah_bayar: this.amount,
              jenis_transfer: this.transfer_type, 
              tgl_bayar : this.date
            })
            .then(response => {
              this.$store.commit('setSnackbar', true)
              this.$store.commit('setText', response.data.message)
              this.$refs.form.reset()
            })
            .catch(error => {
              this.errorHandling(error)
            })
            .finally(() => {
              this.$store.commit('setIsLoading', false)
            })
        }
      }
    }, 
    mounted () {
      if (this.$route.params.id) {
        this.initForm()
      }

      this.setMetaInfo(null, 'Confirm Payments')

      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setIsMemberPage', true)
      this.$store.commit('setActivePage', this.$t('payment-confirm.title'))
    }
  }
</script>

<style scoped>
  .text {
    color: #000000;
    margin-bottom: 5px;
    font-size: 0.9rem !important;
  }
</style>
